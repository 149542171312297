import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { connect } from "react-redux";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";

const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu,
  category,
}) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <footer
      className={`footer-area home1footer ${backgroundColorClass ? backgroundColorClass : ""
        } ${spaceTopClass ? spaceTopClass : ""} ${spaceBottomClass ? spaceBottomClass : ""
        } ${extraFooterClass ? extraFooterClass : ""} ${spaceLeftClass ? spaceLeftClass : ""
        } ${spaceRightClass ? spaceRightClass : ""}`}
    >
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          <div
            className={`${sideMenu
                ? "col-lg-5 col-md-6 col-sm-4"
                : "col-lg-5  col-md-6 col-sm-4"
              }`}
          >
             <FooterCopyright
                footerLogo="/assets/img/logo/ss-logo-footer.png"
              /> {" "}
            {/* <h3 class="f-title f_600 t_color f_size_16"> SK Bikes Pvt Ltd. </h3>{" "} */}
            <h5 class="pt-3">
              {" "}
              <b> Address: </b> D 233 - 235, Phase VII, Focal Point, Ludhiana
              141010, Punjab, India. <br /><br />
              <b> Phone: </b> +91-161-4353000 <br /><br />
              <b> email: </b>{" "}
              <a href="mailto:sales@skbikes.com ">sales@skbikes.com</a> |
              <a href="mailto:info@skbikes.com ">  info@skbikes.com</a>
            </h5>{" "}
          </div>{" "}
          <div
            className={`${sideMenu
                ? "col-lg-2 col-md-6 col-sm-4"
                : "col-lg-2 col-md-6 col-sm-4"
              }`}
          >
            {" "}

            <h3 class="f-title f_600 t_color f_size_16"> Company Links </h3>{" "}
            <ul class="list-unstyled f_list pt-3 com_links">
              <li>
                {" "}
                <a href={process.env.PUBLIC_URL + "/"}>
                  {" "}
                  HOME{" "}
                </a>
              </li>{" "}
              <br />
              <li>
                {" "}
                <a href={process.env.PUBLIC_URL + "/page/about-us"}>
                  {" "}
                  ABOUT US{" "}
                </a>
              </li>{" "}
              <br />
              <li>
                {" "}
                <a href={process.env.PUBLIC_URL + "/contact "}> CONTACT US </a>
              </li>{" "}
              <br />
            </ul>{" "}
          </div>{" "}
          <div
            className={`${sideMenu
                ? "col-lg-2 col-md-6 col-sm-4"
                : "col-lg-2 col-md-6 col-sm-4"
              }`}
          >
            {" "}

            <h3 class="f-title f_600 t_color f_size_16"> Bicycle Types </h3>{" "}
            <ul class="list-unstyled f_list b_cat pt-3">
              {category.items.map((value) => (
                <React.Fragment>
                  {" "}
                  <li>
                    {" "}
                    <a href={process.env.PUBLIC_URL + "/catalog/" + value.slug}>
                      {" "}
                      {value.name}
                    </a>
                  </li>
                  <br />
                </React.Fragment>
              ))}
            </ul>
          </div>
          <div
            className={`${sideMenu
                ? "col-lg-3 col-md-6 col-sm-4"
                : "col-lg-3 col-md-6 col-sm-6"
              }`}
          >
            {" "}

            <h3 class="f-title f_600 t_color f_size_16"> Get In Touch </h3>{" "}
            <div class="f_social_icon">
              <a href="https://www.facebook.com/77bikesindia/" target="_blank" class="fa fa-facebook">
                {" "}
              </a>{" "}
              <a href="https://www.instagram.com/77bikesindia/" target="_blank" class="fa fa-instagram">
                {" "}
              </a>{" "}
              {/* <a href="#" target="_blank" class="fa fa-twitter">
                {" "}
              </a>{" "}
              <a href="#" target="_blank" class="fa fa-linkedin">
                {" "}
              </a>{" "} */}
              <a href="https://www.youtube.com/channel/UCVOgdyDq07ztlRewX-G0c7w" target="_blank" class="fa fa-youtube">
                {" "}
              </a>{" "}
            </div>{" "}
          </div>{" "}

        </div>{" "}
        <hr />
        <div className="row">
          <div className="col-lg-12">
              <span className="float-left text-white">© 2021 Seventy Seven | All Right Reserved</span>
              <span className="float-right text-white">
                <a href="https://www.cyberframe.in" rel="noopener noreferrer" target="_blank">
                  Devoloped by: CYBERFRAME
                </a>
              </span>
          </div>
        </div>

      </div>

      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <i className="fa fa-angle-double-up"> </i>{" "}
      </button>{" "}
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

const mapStateToProps = state => {
  
  return {
    category: state.category,
  };
}


export default connect(mapStateToProps)(FooterOne);
