
/****** Save Address Actions *********/
export const REVIEW_LIST = 'REVIEW_LIST';
export const REVIEW_LIST_SUCCESS = 'REVIEW_LIST_SUCCESS';
export const REVIEW_LIST_FAILED = 'REVIEW_LIST_FAILED';

/****** Create Review Actions *********/
export const CREATE_REVIEW = 'CREATE_REVIEW';
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
export const CREATE_REVIEW_FAILED = 'CREATE_REVIEW_FAILED';

