import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { getProducts } from "../../helpers/product";
import ProductGridSingle from "../../components/product/ProductGridSingle";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";

const ProductGrid = ({
  products,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItems,
  wishlistItems,
  compareItems,
  sliderClassName,
  spaceBottomClass,
  titlePriceClass,
  cat,
  productnewarrivals,
  bestsellers,
  saleitems,
  productlist,
}) => {
  switch (cat) {
    case "newarrival":
      products = productnewarrivals.items;
      break;
    case "bestseller":
      products = bestsellers.items;

      break;
    case "salesitems":
      products = saleitems.items;

      break;
  }

  console.log("product data coming here", products);
  return (
    <Fragment>
      {productlist.length > 0 ? (  
        productlist.map((product) => {
          console.log("product detail coming here", product);
          return (
            <ProductGridSingle
              sliderClassName={sliderClassName}
              spaceBottomClass={spaceBottomClass}
              product={product}
              currency={currency}
              addToCart={addToCart}
              addToWishlist={addToWishlist}
              addToCompare={addToCompare}
              cartItem={
                cartItems.filter((cartItem) => cartItem.id === product.id)[0]
              }
              wishlistItem={
                wishlistItems.filter(
                  (wishlistItem) => wishlistItem.id === product.id
                )[0]
              }
              compareItem={
                compareItems.filter(
                  (compareItem) => compareItem.id === product.id
                )[0]
              }
              key={product.id}
              titlePriceClass={titlePriceClass}
            />
          );
        })
      ) : (
        <div className="no_record_com"> No Product available!</div>
      )}
    </Fragment>
  );
};

ProductGrid.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  products: PropTypes.array,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItems: PropTypes.array,
  titlePriceClass: PropTypes.string,
  productlist:PropTypes.any
};

const mapStateToProps = (state, ownProps) => {
  return {
    products: getProducts(
      state.productData.products,
      ownProps.category,
      ownProps.type,
      ownProps.limit
    ),
    currency: state.currencyData,
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData,
    productnewarrivals:state.newarrivals,
    bestsellers:state.bestsellers,
    saleitems:state.saleitems,
    cat:ownProps.category
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductGrid);
