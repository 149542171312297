import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../helpers/product";
import Rating from "./sub-components/ProductRating";
import ProductModal from "./ProductModal";

const ProductGridSingle = ({
  product,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItem,
  wishlistItem,
  compareItem,
  sliderClassName,
  spaceBottomClass,
  titlePriceClass,
  titleClass
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { addToast } = useToasts();

  const discountedPrice = getDiscountPrice(product.price, 0);
  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2);
  const finalDiscountedPrice = +(
    discountedPrice * currency.currencyRate
  ).toFixed(2);
  console.log("variation data coming here 11", product.images);
  return (<Fragment >
    <div 
           className={`col-xl-3 col-md-6 col-lg-4 col-sm-6 ${
        sliderClassName ? sliderClassName : ""
        }`}
    >
      <div
        className={`product-wrap mb-50 ${spaceBottomClass ? spaceBottomClass : ""}`}
      >
        <div className="product-img">
          <Link to={process.env.PUBLIC_URL + "/product/" + product.url_key}>
            <img
              className="default-img"
              src={product.images != undefined && product.images.length > 0 ? product.images[0].large_image_url : ''}
              alt=""
            />
            {product.images != undefined && product.images.length > 0 ? (
              <img
                className="hover-img"
                src={product.images[0].large_image_url}
                alt=""
              />
            ) : (
              ""
            )}
          </Link>
          {product.new ? (
            <div className="product-img-badges">
              {product.discount ? (
                <span className="pink">-{product.discount}%</span>
              ) : (
                ""
              )}
              {product.new ? <span className="purple">New</span> : ""}
            </div>
          ) : (
            ""
          )}



          {/* <div className="product-action">
            <div className="pro-same-action pro-wishlist">
              <button
                className={wishlistItem !== undefined ? "active" : ""}
                disabled={wishlistItem !== undefined}
                title={
                  wishlistItem !== undefined
                    ? "Added to wishlist"
                    : "Add to wishlist"
                }
                onClick={() => addToWishlist(product, addToast)}
              >
                <i className="pe-7s-like" />
              </button>
            </div>
            <div className="pro-same-action pro-cart">
              {product.affiliateLink ? (
                <a
                  href={product.affiliateLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                    Buy now{" "}
                </a>
              ) : product.variants && product.variants.length >= 1 ? (
                <Link to={`${process.env.PUBLIC_URL}/product/${product.url_key}`}>
                  Select Option
                </Link>
              ) : product.in_stock ? (
                <button
                  onClick={() => addToCart(product, addToast)}
                  className={
                    cartItem !== undefined && cartItem.quantity > 0
                      ? "active"
                      : ""
                  }

                  title={
                    cartItem !== undefined ? "Add to cart" : "Add to cart"
                  }
                >
                  {" "}
                  <i className="pe-7s-cart"></i>{" "}
                  {cartItem !== undefined && cartItem.quantity > 0
                    ? "Add to cart"
                    : "Add to cart"}
                </button>
              ) : (
                <button disabled className="active">
                  Out of Stock
                </button>
              )}
            </div>
            <div className="pro-same-action pro-quickview">
              <button onClick={() => setModalShow(true)} title="Quick View">
                <i className="pe-7s-look" />
              </button>
            </div>
          </div> */}



        </div>
        <div className={`product-content text-center ${titlePriceClass ? titlePriceClass : ""
          }`}>
          <h2>
            <Link to={process.env.PUBLIC_URL + "/product/" + product.url_key} className={titleClass}>
              {product.name}
            </Link>
          </h2>
            {/* <Link to={process.env.PUBLIC_URL + "/product/" + product.url_key}>
              <h3 dangerouslySetInnerHTML={{__html: product.short_description.replace(/<[^>]+>/g, ''),}}></h3>
            </Link> */}
          {product.rating && product.rating > 0 ? (
            <div className="product-rating">
              <Rating ratingValue={product.rating} />
            </div>
          ) : (
            ""
          )}
          {/* <div className="product-price">
            {product.variants != undefined && product.variants.length >= 1 ? <span>{currency.currencySymbol + product.variants[0].price} - {currency.currencySymbol + product.variants[product.variants.length - 1].price} </span> : <span>{currency.currencySymbol + finalProductPrice} </span>}
          </div> */}
        </div>
      </div>
      
    </div>


    {/* product modal */}
    <ProductModal
      show={modalShow}
      onHide={() => setModalShow(false)}
      product={product}
      currency={currency}
      discountedprice={discountedPrice}
      finalproductprice={finalProductPrice}
      finaldiscountedprice={finalDiscountedPrice}
      cartitem={cartItem}
      wishlistitem={wishlistItem}
      compareitem={compareItem}
      addtocart={addToCart}
      addtowishlist={addToWishlist}
      addtocompare={addToCompare}
      addtoast={addToast}
    />
  </Fragment>
  );
};

ProductGridSingle.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.object,
  titlePriceClass: PropTypes.string,
  titleClass: PropTypes.string
};

export default ProductGridSingle;