import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { contactSubscribeRequest } from "../../redux/page";
import SubscribeEmail from "./sub-components/SubscribeEmail";
import { useToasts } from "react-toast-notifications";
import { ButtonLoader } from "../../components/loader/loader";
import { connect } from "react-redux";

const FooterNewsletter = ({
  spaceBottomClass,
  spaceLeftClass,
  sideMenu,
  colorClass,
  widgetColorClass,
  contactrequest,
  contact,
}) => {
  const { addToast } = useToasts();
  const [contactform, setContactForm] = useState({
    email: "",
  });

  const keyUpEvent = (event) => {
    setContactForm({
      ...contactform,
      ...{ [event.target.name]: event.target.value },
    });
  };
  /******* contact Action Request */
  var contactrequestAction = (event) => {
    event.preventDefault();
    var error = false;
    if (document.getElementById("mc-form-email").value == '') { 

        addToast('Please Enter Email', {
          appearance: "error",
          autoDismiss: true,
        });
      return false;
    }
    contactrequest(contactform, addToast);
    document.getElementById("mc-form-email").value = '';
  
  };
  return (
    <div
      className={`footer-widget ${spaceBottomClass ? spaceBottomClass : ""} ${
        sideMenu ? "ml-ntv5" : spaceLeftClass ? spaceLeftClass : ""
      } ${widgetColorClass ? widgetColorClass : ""}`}
    >
      <div className="footer-title">
        <h3>SUBSCRIBE</h3>
      </div>
      <div className={`subscribe-style ${colorClass ? colorClass : ""}`}>
        <p>Get E-mail updates about our latest shop and special offers.</p>
        {/* subscribe email */}
        <div class="subscribe-form">
          <div class="mc-form">
            <div>
              <input
                id="mc-form-email"
                class="email"
                type="email"
                name="email"
                
                onKeyUp={keyUpEvent}
                placeholder="Enter your email address..."
              />
            </div>
            <div class="clear">
              {contact.loading ? (
                <ButtonLoader loadingtxt="Processing.." />
              ) : (
                <button
                  class="button"
                  type="button"
                  // onClick={contactrequestAction}
                >
                  SUBSCRIBE
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterNewsletter.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  colorClass: PropTypes.string,
  widgetColorClass: PropTypes.string
};


const mapStateToProps = (state) => {
  return {
    contact: state.contact.subscribe,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    contactrequest: (data, addToast) => {
      dispatch( contactSubscribeRequest(data, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterNewsletter);