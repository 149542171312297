import React, { Fragment, useEffect } from "react";

import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import HeroSliderFourteen from "../../wrappers/hero-slider/HeroSliderFourteen";
// import FeatureIcon from "../../wrappers/feature-icon/FeatureIcon";
// import FeatureIconSeven from "../../wrappers/feature-icon/FeatureIconSeven";
// import AdvanceSearch from "../../components/custom/AdvanceSearch";
import WeRecommend from "../../components/custom/WeRecommend";
import TabProductEight from "../../wrappers/product/TabProductEight";
import { getBestSellerProduct } from "../../redux/product/productActions"
// import { getBrands } from "../../redux/product"
import { getproductWithAdditionalDetails } from "../../redux/product/productActions"
import { Link } from "react-router-dom";
const SSHome = ({ category, getBestSellerProduct, getproductWithAdditionalDetails  }) => {
    useEffect(() => {
        // getnewproduct({ limit: 8, category: 1, attribute: "&featured=1" });
        getBestSellerProduct({ limit: 8, category: 1, attribute: "&bestseller=1" });
        // getSaleItemProduct({ limit: 8, category: 1, attribute: "&saleitem=1" });
        getproductWithAdditionalDetails();
    }, []);
    console.log("categories  all data coming here", category)
    return (
        <Fragment>
            <MetaTags>
                <title>Seventy Seven Bicycles</title>
                <meta name="description" content="Huge" />
            </MetaTags>
            <LayoutOne
                headerContainerClass="container-fluid"
                headerPaddingClass="header-padding-1"
            >
                <HeroSliderFourteen />

                {/* <AdvanceSearch /> */}

                <WeRecommend />

                <TabProductEight
                    spaceBottomClass="pb-30 pt-80"
                    category="Homepage"
                    bgColorClass="theme-color"
                    sectionTitle={true}
                />

                {/* <FeatureIconSeven
                    bgImg="/assets/img/bg/shape.png"
                    containerClass="container-fluid"
                    gutterClass="padding-10-row-col"
                    spaceTopClass="pt-50"
                    spaceBottomClass="pb-40"
                /> */}

                {/* <FeatureIcon spaceBottomClass="pt-50 pb-60" /> */}

            </LayoutOne>
        </Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        category: state.category,
        product: state.productData
    }
}
const mapDispatchToProps = {
    // getBrands: getBrands,
    getproductWithAdditionalDetails:getproductWithAdditionalDetails,
    // getnewproduct: getnewproduct,
    getBestSellerProduct: getBestSellerProduct,
    // getSaleItemProduct: getSaleItemProduct

}
export default connect(mapStateToProps, mapDispatchToProps)(SSHome);
