import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM,WISHLIST_LIST,WISHLIST_LIST_FAILED,WISHLIST_LIST_SUCCESS } from './wishlistActionTypes';

function addItem(state, product) {
    const itemIndex = state.findIndex((x) => x.id === product.id);

    if (itemIndex === -1) {
        return [
            ...state,
            JSON.parse(JSON.stringify(product)),
        ];
    }

    return state;
}

const initialState = [];

export default function wishlistReducer(state = initialState, action) {
    switch (action.type) {
    case WISHLIST_ADD_ITEM:
        return addItem(state, action.product);

    case WISHLIST_REMOVE_ITEM:
        return state.filter((x) => x.id !== action.productId);

    default:
        return state;
    }
}


const initialdataState = {

    items:[],
    loading:true
};

export  function wishlistDataReducer(state = initialdataState, action) { 
    console.log("wish list type coming here",action.type) 
    switch (action.type) {
    case WISHLIST_LIST:
           console.log("loading data coming here",action.loading)
            return {...state,...{loading:action.loading,items:action.list}};
    
    case WISHLIST_LIST_SUCCESS:
        return {...state,...{items:action.list,loading:false}};

    case WISHLIST_LIST_FAILED:
        return {...state,...{items:action.list}};

    default:
        return state;
    }
}
