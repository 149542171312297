export const ApiEndpointUrl = "https://77bikes.com/ssadmin/public/api/";
export const storageUrl = "https://77bikes.com/ssadmin/public/storage/";
export const clientLogoUrl = "https://77bikes.com/public/cache/large/";
export const frontUrl = "https://77bikes.com"; 

// export const ApiEndpointUrl = "https://hugecycles.com/hugeadmin/public/api/";
// export const storageUrl = "https://hugecycles.com/hugeadmin/public/storage";
// export const clientLogoUrl = "https://hugecycles.com/hugeadmin/public/cache/large/";
// export const frontUrl = "https://hugecycles.com";

export const Logintoken = ()=>{
      
    if(localStorage.getItem('auth') !=undefined)
    {
        return JSON.parse(localStorage.getItem('auth')).token
    }
    return '';
}
export default {};
