import React, { Fragment } from "react";


/****** Loaders */
export  const SimpleLoader=()=>
{
return (<div class="spinner-grow" style={{width: '3rem', height:'3rem'}} role="status">
<span class="sr-only">Loading...</span>
</div>)
}

/***** Button Loader ****/
export const ButtonLoader =({loadingtxt})=>
{

  return (<button class="btn " type="button" disabled>
  <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
 {loadingtxt}
</button>)

}


export default {}