import {
  ORDERS_LIST,
  ORDERS_LIST_SUCCESS,
  ORDERS_LIST_FAILED,
  ORDERS_DETAIL,
  ORDERS_DETAIL_SUCCESS,
  ORDERS_DETAIL_FAILED,
  
} from "./orderActionTypes";

/*
 * item example:
 * {
 *   id: 1,
 *   product: {...}
 *   options: [
 *     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
 *   ],
 *   price: 250,
 *   quantity: 2,
 *   total: 500
 * }
 * extraLine example:
 * {
 *   type: 'shipping',
 *   title: 'Shipping',
 *   price: 25
 * }
 */
const initialState = {
    loading: 0,
    items: [],
    orderdetail: { items:{},loading:false},
    meta:{current_page:1,total:0}
};

export  function ordersReducer(state = initialState, action) {
    console.log("checking action typre")
    switch (action.type) {
      case ORDERS_LIST:
        return {
          ...state,
          ...{ items: [], meta: { current_page: 1, total: 0 }, loading: true },
        };
      case ORDERS_LIST_SUCCESS:
        return {
          ...state,
          ...{
            items: action.items.data,
            meta: action.items.meta,
            loading: false,
          },
        };
      case ORDERS_LIST_FAILED:
        return {
          ...state,
          ...{ items: [], meta: { current_page: 1, total: 0 }, loading: false },
        };
            case ORDERS_DETAIL:
              return {
                ...state,
                  ...{
                      orderdetail: { items: {}, loading: true } },
              };
            case ORDERS_DETAIL_SUCCESS:
              return {
                ...state,
                ...{
                    orderdetail: {
                        items: action.items,
                        loading: false,
                    }
                },
              };
            case ORDERS_DETAIL_FAILED:
              return {
                ...state,
                ...{
                  orderdetail: { items: {}, loading: true },
                },
              };
      default:
        return state;
    }  
}

export default {}       