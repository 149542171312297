import React, { Component } from "react";
import { Fragment } from "react";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import ReactTooltip from 'react-tooltip';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { frontUrl, storageUrl } from "../../common";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={"next slick-arrow fa fa-angle-right"}
      style={{ ...style, borderRadius: "25px", fontSize: "50px" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={"prev slick-arrow fa fa-angle-left"}
      style={{ ...style, borderRadius: "25px", fontSize: "50px" }}
      onClick={onClick}
    />
  );
}

const NewLaunches = ({ newProductsWithAllData }) => {

  const settings = {
    centerMode: true,
    centerPadding: '0px',
    slidesToShow: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [{
      breakpoint: 1169,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 969,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    }
    ]
  };
  return (
    <Fragment>
      <div
        className="product-area pt-40 hm9-section-padding bg-shaded-light"
      >
        <div class="container-fluid">
          <div className="welcome-content text-center" >
            <h1>We Recommend</h1>
          </div>
          <div class="featured-slider single-products text-center">
            <Slider {...settings}>
              {newProductsWithAllData.items.length > 0 ? (
                newProductsWithAllData.items.map((product) => {
                  console.log('We recommend prod', product);
                  return (
                    <div class="single-slide">
                      <div class="padding30">
                        <div class="product-item">
                          {/* <div className="product-img-badges text-right">
                                                        <span className="new-badge bg-dark">New </span>
                                                    </div> */}
                          <div class="pro-img">
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                "/product/" +
                                product.basic_info.url_key
                              }
                              onClick={(event) =>
                                localStorage.setItem(
                                  "pro" + product.url_key,
                                  product.basic_info.name
                                )
                              }
                            >
                              <img
                                src={
                                  product.basic_info.images != undefined &&
                                    product.basic_info.images.length > 0
                                    ? product.basic_info.images[0].large_image_url
                                    : ""
                                }
                                alt=""
                              />
                            </Link>
                          </div>
                          {/* <div class="actions-btn">
                            <ul class="clearfix">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/product/" +
                                    product.url_key
                                  }
                                  onClick={(event) =>
                                    localStorage.setItem(
                                      "pro" + product.url_key,
                                      product.basic_info.name
                                    )
                                  }
                                >
                                  {product.basic_info.name}
                                </Link>
                              </li>
                            </ul>
                          </div> */}
                          <div class="product-title">
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                "/product/" +
                                product.basic_info.url_key
                              }
                              onClick={(event) =>
                                localStorage.setItem(
                                  "pro" + product.url_key,
                                  product.basic_info.name
                                )
                              }
                            >
                            </Link>
                          </div>
                          <div class="product-desc">
                            <span class="product-title">
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  "/product/" +
                                  product.basic_info.url_key
                                }
                                onClick={(event) =>
                                  localStorage.setItem(
                                    "pro" + product.url_key,
                                    product.basic_info.name
                                  )
                                }
                              >
                                <b>Seventy Seven</b> {product.basic_info.name}
                              </Link>
                              <span class="badge">Bestseller</span>
                            </span>

                            <span class="product-caption d-block my-2">
                              {product.basic_info.cat[0].name}
                            </span>

                            <span class="product-feature-mf">
                              <img src={"https://77bikes.com/ssadmin/public/storage/spec_Images/mf.png"} alt="" /><span class="ml-2">
                                {/* for cycle gender */}
                                {product.basic_info.additional_info[33].value}
                              </span>
                            </span>

                            <span class="product-feature-age ml-3">
                              <img src={"https://77bikes.com/ssadmin/public/storage/spec_Images/age.png"} alt="" /> <span class="ml-2">
                                {/* for cycle age */}
                                {product.basic_info.additional_info[34].value}
                              </span>
                            </span>

                            <span class="product-feature-wheel ml-3">
                              <img src={"https://77bikes.com/ssadmin/public/storage/spec_Images/tyre_size.png"} alt="" /><span class="ml-2">
                                {/* for cycle tyre size */}
                                {product.basic_info.additional_info[5].value}
                              </span>
                            </span>

                            <span class="product-feature-height ml-3">
                              <img src={"https://77bikes.com/ssadmin/public/storage/spec_Images/frame_size.png"} alt="" /><span class="ml-2">
                                {/* for cycle frame size */}
                                {product.basic_info.additional_info[28].value}
                              </span>
                            </span>

                            <span class="product-feature-frame mt-3">
                              <img src={"https://77bikes.com/ssadmin/public/storage/spec_Images/frame.png"} alt="" />
                              <span>
                                {/* for cycle frame */}
                                {product.basic_info.additional_info[3].value}
                              </span>
                            </span>

                            <span class="product-feature-suspension">
                              <img src={"https://77bikes.com/ssadmin/public/storage/spec_Images/fork.png"} alt="" /><span>
                                {/* for cycle fork */}
                                {product.basic_info.additional_info[4].value}
                              </span>
                            </span>

                            <span class="product-feature-brake">
                              <img src={"https://77bikes.com/ssadmin/public/storage/spec_Images/brakes.png"} alt="" /><span>
                                {/* for cycle brakes */}
                                {product.basic_info.additional_info[18].value}
                              </span>
                            </span>

                            <span class="product-feature-gear">
                              <img src={"https://77bikes.com/ssadmin/public/storage/spec_Images/speeds.png"} alt="" /><span>
                                {/* for cycle speed */}
                                {product.basic_info.additional_info[22].value}
                              </span>
                            </span>

                            <div className="col-lg-12 text-right">
                              <span id="product-color" class="product-color">
                                {product.configurable_options.map((opt) => {
                                  return getColor(opt.options);
                                }
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                  function getColor(listOfColors) {
                    let listOfC = [];
                    for (let i = 0; i < listOfColors.length; i++) {
                      listOfColors[i].swatch_value != null ?  listOfC.push(<span title={listOfColors[i].label} className="fa fa-circle" style={{color: listOfColors[i].swatch_value, backgroundColor: listOfColors[i].swatch_value  }}></span>) : console.log("No Color Found", listOfColors[i].swatch_value);
                    }
                    return listOfC;
                  }
                })
              ) : (
                <div className="no_record_com"> No Product available!</div>
              )}
            </Slider>
          </div>
        </div>
      </div>
    </Fragment>
  );

}

const mapStateToProps = (state, ownProps) => {
  return {
    productlist: state.product,
    newProductsWithAllData: state.newArrivalsWithAllData
  };
};

export default connect(mapStateToProps)(NewLaunches);
