import {
  PRODUCT_LIST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAILED,
  PRODUCT_DETAIL,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_DETAIL_FAILED,
  PRODUCT_VARIATION_DETAIL,
  PRODUCT_VARIATION_DETAIL_SUCCESS,
  PRODUCT_VARIATION_DETAIL_FAILED,
  NEW_PRODUCT_LIST,
  NEW_PRODUCT_LIST_SUCCESS,
  NEW_PRODUCT_LIST_FAILED,
  BRANDS_LIST_SUCCESS,
  PRODUCT_ADDITIONALS,
  PRODUCT_ADDITIONALS_SUCCESS,
  PRODUCT_ADDITIONALS_FAILED,
  PRODUCT_FILTERS_SUCCESS,
  PRODUCT_FILTERS,
  PRODUCT_RECENT_VIEWED_LIST,
  BEST_SELLER_LIST,
  BEST_SELLER_LIST_SUCCESS,
  BEST_SELLER_LIST_FAILED,
  SALE_ITEM_LIST,
  SALE_ITEM_LIST_SUCCESS,
  SALE_ITEM_LIST_FAILED, 
  PRODUCT_WITH_ADDITIONAL_DETAILS_LIST, 
  PRODUCT_WITH_ADDITIONAL_DETAILS_LIST_FAILED, 
  PRODUCT_WITH_ADDITIONAL_DETAILS_LIST_SUCCESS
} from "./productActionTypes";

/*
 * item example:
 * {
 *   id: 1,
 *   product: {...}
 *   options: [
 *     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
 *   ],
 *   price: 250,
 *   quantity: 2,
 *   total: 500
 * }
 * extraLine example:
 * {
 *   type: 'shipping',
 *   title: 'Shipping',
 *   price: 25
 * }
 */
const initialState = {
    loading: 0,
    items: [],
    itemmeta: { from: 0, tp: 0, total: 0 },
};

export default function productlistReducer(state = initialState, action) {
    console.log("product list reducer",action.type)
    switch (action.type) {
        case PRODUCT_LIST:
            return {
              ...state,
              ...{
                loading: action.loading,
                items: action.items
              },
            };
        case PRODUCT_LIST_SUCCESS:
            return { ...state, ...{ loading: action.loading, items: action.items, meta: action.itemmeta } };
        case PRODUCT_LIST_FAILED:
            return { ...state, ...{ loading: action.loading, items: action.items, meta: action.itemmeta } };

        default:
            return state;
    }
}

let initialdetailState = { loading: 0, items: { data: [] } };
/******* product Detail Reducer */
export function detailProductReducer(state = initialdetailState, action) {
    switch (action.type) {
        case PRODUCT_DETAIL:
            return { ...state, ...{ loading: action.loading,items:[] } };
        case PRODUCT_DETAIL_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };
        case PRODUCT_DETAIL_FAILED:
            return { ...state, ...{ items: action.items, loading: false } };
        default:
            return state;
    }
}

let initialdetailadditionsState = { loading: 0, items: [] };
/******* product Detail Reducer */
export function detailProductAdditionalsReducer(state = initialdetailadditionsState, action) {
    switch (action.type) {
        case PRODUCT_ADDITIONALS:
            return { ...state, ...{ loading: action.loading } };
        case PRODUCT_ADDITIONALS_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };
        case PRODUCT_ADDITIONALS_FAILED:
            return { ...state, ...{ items: action.items, loading: false } };
        default:
            return state;
    }
}

let initialdetailvariationConfigurationState = { loading: 0, items: [] };
/******* product Detail Reducer */
export function detailProductVariationsReducer(state = initialdetailvariationConfigurationState, action) {
    switch (action.type) {
        case PRODUCT_VARIATION_DETAIL:
            return { ...state, ...{ loading: action.loading, items: action.items } };
        case PRODUCT_VARIATION_DETAIL_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };
        case PRODUCT_VARIATION_DETAIL_FAILED:
            return { ...state, ...{ items: action.items, loading: false } };
        default:
            return state;
    }
}

const newProductInitialState = {
  loading: 0,
  items: [],
  bestseller: { items: [], loading: false },
  saleItem: { items: [], loading: false },
};

/*** New Arrival  Reducer */
export function newProductReducer(state = newProductInitialState, action) {
    switch (action.type) {
      case NEW_PRODUCT_LIST:
        return { ...state, action };
      case NEW_PRODUCT_LIST_SUCCESS:
        return { ...state, ...{ items: action.items } };
      case NEW_PRODUCT_LIST_FAILED:
        return { ...state, ...{ items: action.items } };
      case BEST_SELLER_LIST:
        return {
          ...state,
          ...{ bestseller: { items: [], loading: true } },
        };
      case BEST_SELLER_LIST_SUCCESS:
        return {
          ...state,
          ...{ bestseller: { items: action.items, loading: false } },
        };
      case BEST_SELLER_LIST_FAILED:
        return {
          ...state,
          ...{ bestseller: { items: action.items, loading: false } },
        };
      case SALE_ITEM_LIST:
        return {
          ...state,
          ...{ saleItem: { items: [], loading: true } },
        };
      case SALE_ITEM_LIST_SUCCESS:
        return {
          ...state,
          ...{ saleItem: { items: action.items, loading: false } },
        };
      case SALE_ITEM_LIST_FAILED:
        return {
          ...state,
          ...{ saleItem: { items: action.items, loading: false } },
        };

      default:
        return state;
    }
}


const productWithAdditionalDetailsInitialState = {
  loading: 0,
  items: [],
};

/*** product with additional details Reducer */
export function productWithAdditionalDetailsReducer(state = productWithAdditionalDetailsInitialState, action) {
    switch (action.type) {
      case PRODUCT_WITH_ADDITIONAL_DETAILS_LIST:
        return { ...state, action };
      case PRODUCT_WITH_ADDITIONAL_DETAILS_LIST_SUCCESS:
        return { ...state, items: action.items };
      case PRODUCT_WITH_ADDITIONAL_DETAILS_LIST_FAILED:
        return { ...state, items: action.items };
      default:
        return state;
    }
}


/*** New Arrival  Reducer */
export function brandReducer(state = newProductInitialState, action) {
    switch (action.type) {
        case BRANDS_LIST_SUCCESS:
            return { ...state, ...{ items: action.items } };
        default:
            return state;
    }
}

const productFiltersInitialState = {
    loading: 0,
    items: [],
};

/******** Product Filters ******/
export function productFiltersReducer(state = productFiltersInitialState, action) {
    switch (action.type) {
        case PRODUCT_FILTERS:
            return { ...state, ...{ loading: true } };
        case PRODUCT_FILTERS_SUCCESS:
            return { ...state, ...{ items: action.items } };
        case "SET_OPTION_VALUE":
            return {
                ...state,
                options: { ...state.options, page: 1, [action.option]: action.value },
            };
        case "SET_FILTER_VALUE":
            return {
                ...state,
                options: { ...state.options, page: 1 },
                filters: { ...state.filters, [action.filter]: action.value },
            };
        case "RESET_FILTERS":
            return { ...state, options: { ...state.options, page: 1 }, filters: {} };
        case "RESET":
            return state.init ? initialState : state;
        default:
            return state;
    }
}

let recentViewedInitialState = {
    loading: 0,
    items: [],
};
/*** Recent viewed  Product */
export function recentViewedReducer(state = recentViewedInitialState, action) {
    switch (action.type) {
        case PRODUCT_RECENT_VIEWED_LIST:
            return { ...state, ...{items: action.items} };
    }
}
