import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const MobileNavMenu = ({ brands, category, spares, accessories, strings }) => {
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/"}>{strings["home"]}</Link>
        </li>
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/page/about-us"}>About Us</Link>
        </li>
        <li className="menu-item-has-children">
          <Link to="#">Bicycle Types</Link>
          <ul className="sub-menu">
            {category.items.map((values, index) => {
              return (
                <li>
                  <Link to={process.env.PUBLIC_URL + "/catalog/" + values.slug}>
                    {values.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </li>
        <li className="menu-item-has-children">
          <a href={process.env.PUBLIC_URL + "/contact "}>Contact Us</a>
        </li>
        {/* <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/"}>SHOP BY BRANDS</Link>
          <ul className="sub-menu">
            {brands.items.map((value, index) => {
              return (
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + "/catalog?brand=" + value.id}
                  >
                    {value.admin_name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </li> */}

        {/* <li className="menu-item-has-children">
          <Link to="#">ACCESSORIES</Link>
          <ul className="sub-menu">
            {accessories.map((values, index) => {
              return (
                <li>
                  <Link to={process.env.PUBLIC_URL + "/catalog/" + values.slug}>
                    {values.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </li> */}
        {/* <li className="menu-item-has-children">
          <Link to="#"> SPARES</Link>
          <ul className="sub-menu">
            {spares.map((values, index) => {
              return (
                <li>
                  <Link to={process.env.PUBLIC_URL + "/catalog/" + values.slug}>
                    {values.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </li> */}

        {/* <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>
            {strings["contact_us"]}
          </Link>
        </li> */}
      </ul>
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object,
};

export default multilanguage(MobileNavMenu);
