import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import SectionTitleWithText from "../../components/section-title/SectionTitleWithText";
import BannerOne from "../../wrappers/banner/BannerOne";
import TextGridOne from "../../wrappers/text-grid/TextGridOne";
import FunFactOne from "../../wrappers/fun-fact/FunFactOne";
import TeamMemberOne from "../../wrappers/team-member/TeamMemberOne";
import BrandLogoSliderOne from "../../wrappers/brand-logo/BrandLogoSliderOne";
import BrandLogoSliderFive from "../../wrappers/brand-logo/BrandLogoSliderFive";
import { Button } from "react-scroll";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { getorderdetail } from "../../redux/order";

const OrderSuccess = ({ location, getorderdetail, match, orderdata }) => {
  const { pathname } = location;
    useEffect(() => {
      console.log("Address id coming here", match.params.addressId);
      getorderdetail(match.params.orderid);
    }, [match.params.orderid]);
 console.log("Order Data coming here to check ", orderdata);
  let orderDetail =
    Object.keys(orderdata.orderdetail.items).length === 0
      ? ""
      : orderdata.orderdetail.items;
  
  return (
    <Fragment>
      <MetaTags>
        <title>Suncross | About us</title>
        <meta
          name="description"
          content="About page of flone react minimalist eCommerce template."
        />
      </MetaTags>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        Order Success
      </BreadcrumbsItem>
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />

        {/* section title with text */}
        <div className="welcome-area">
          <div className="container">
            <div className="welcome-content text-center">
              <br />
              <br />
              <h3>
                Thanks for your order, your Order is placed successfully!
              </h3>{" "}
              <br />
              <br />
              <div className="shopping-cart-btn btn-hover text-center">
                {" "}
                <Link to="/public/front" className="default-btn">
                  Continue Shopping
                </Link>
              </div>
              <br />
              <br />
              {orderDetail != "" ? (
                <div className="card-body">
                  <div className="row no-gutters">
                    <div className="col-12 col-lg-12 col-xl-12">
                      <div class="card card-1">
                        <div class="card-header bg-white">
                          <div class="media flex-sm-row flex-column-reverse justify-content-between ">
                            <div class="col my-auto">
                              <h4 class="mb-0">
                                <b>Order #{orderDetail.id}</b>
                                <span class="change-color"></span>{" "}
                              </h4>
                            </div>
                            <div class="col-auto text-center my-auto pl-0 pt-sm-4">
                              <p class="mb-4 pt-0 Glasses"></p>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row justify-content-between mb-3">
                            <div class="col-auto">
                              <h6 class="color-1 mb-0 change-color">Receipt</h6>
                            </div>
                          </div>
                          {orderDetail.items.map((item) => (
                            <div class="row">
                              <div class="col">
                                <div class="card card-2">
                                  <div class="card-body">
                                    <div class="media">
                                      <div class="sq align-self-center ">
                                        {" "}
                                        <img
                                          class="img-fluid my-auto align-self-center mr-2 mr-md-4 pl-0 p-0 m-0"
                                          src={
                                            item.product != undefined
                                              ? item.product.base_image
                                                  .small_image_url
                                              : "#"
                                          }
                                          width="135"
                                          height="135"
                                        />{" "}
                                      </div>
                                      <div class="media-body my-auto text-right">
                                        <div class="row my-auto flex-column flex-md-row">
                                          <div class="col my-auto">
                                            <h6 class="mb-0">
                                              {" "}
                                              {item.product.name}
                                            </h6>
                                          </div>
                                          <div class="col-auto my-auto">
                                            {" "}
                                            <small>{item.product.name} </small>
                                          </div>
                                          <div class="col my-auto">
                                            {" "}
                                            <small></small>
                                          </div>
                                          <div class="col my-auto">
                                            {" "}
                                            <small>
                                              Qty : {item.qty_ordered}
                                            </small>
                                          </div>
                                          <div class="col my-auto">
                                            <h6 class="mb-0">
                                              {item.formated_total}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div class="row mt-4">
                            <div class="col">
                             
                              <div class="row justify-content-between">
                                <div class="flex-sm-col text-right col">
                                  <p class="mb-1">
                                    <b>Total</b>
                                  </p>
                                </div>
                                <div class="flex-sm-col col-auto">
                                  <p class="mb-1">
                                    {orderDetail.formated_sub_total}
                                  </p>
                                </div>
                              </div>
                              <div class="row justify-content-between">
                                <div class="flex-sm-col text-right col">
                                  <p class="mb-1">
                                    {" "}
                                    <b>Discount</b>
                                  </p>
                                </div>
                                <div class="flex-sm-col col-auto">
                                  <p class="mb-1">
                                    {orderDetail.formated_discount_amount}
                                  </p>
                                </div>
                              </div>
                              <div class="row justify-content-between">
                                <div class="flex-sm-col text-right col">
                                  <p class="mb-1">
                                    <b>GST 18%</b>
                                  </p>
                                </div>
                                <div class="flex-sm-col col-auto">
                                  <p class="mb-1">
                                    {orderDetail.formated_tax_amount}
                                  </p>
                                </div>
                              </div>
                              <div class="row justify-content-between">
                                <div class="flex-sm-col text-right col">
                                  <p class="mb-1">
                                    <b>Shipping</b>
                                  </p>
                                </div>
                                <div class="flex-sm-col col-auto">
                                  <p class="mb-1">
                                    {orderDetail.shipping_description}
                                  </p>
                                </div>
                              </div>
                              <div class="row justify-content-between">
                                <div class="flex-sm-col text-right col">
                                  <p class="mb-1">
                                    <b>Order Status</b>
                                  </p>
                                </div>
                                <div class="flex-sm-col col-auto">
                                  <p class="mb-1">{orderDetail.status_label}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {orderDetail.invoices.length > 0 &&
                            orderDetail.invoices.map((invoice) => {
                              console.log(
                                "Invoices data coming here ",
                                invoice
                              );
                              return (
                                <div class="row invoice ">
                                  <div class="col">
                                    <p class="mb-6">
                                      {" "}
                                      <b>Invoice Number</b> : #{invoice.id}
                                    </p>
                                    <p class="mb-6">
                                      <b>Invoice Date</b> :{" "}
                                      {moment(invoice.created_at).format(
                                        "MMMM Do YYYY"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        <div class="card-footer">
                          <div class="jumbotron-fluid">
                            <div class="row justify-content-between ">
                              <div class="col-sm-auto col-auto my-auto"></div>
                              <div class="col-auto my-auto ">
                                <h2 class="mb-0 font-weight-bold">TOTAL</h2>
                              </div>
                              <div class="col-auto my-auto ml-auto">
                                <h1 class="display-3 ">
                                  {orderDetail.formated_grand_total}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {/* banner */}
        {/* <BannerOne spaceBottomClass="pb-70" /> */}

        {/* text grid */}
      </LayoutOne>
    </Fragment>
  );
};

OrderSuccess.propTypes = {
  location: PropTypes.object,
};

const mapStateToProps = (state) => ({
  orderdata: state.orderdata,
});

const mapDispatchToProps = {
  getorderdetail,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderSuccess);
