import {BANNER_LIST, BANNER_LIST_SUCCESS, BANNER_LIST_FAILED } from "./bannerActionTypes";
import { bannerPoint } from "./endpoint";

export function bannerSuccess(banners) {
    return {
        type: BANNER_LIST_SUCCESS,
        items: banners.list,
    };
}

export function bannerFailed(error) {
    return {
        type: BANNER_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getbanner() {
    // sending request to server, timeout is used as a stub
    console.log("get banners coming here")
    return (dispatch) => {
        fetch(bannerPoint.list)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                console.log("coming in response here", data);
                dispatch(bannerSuccess(data));
            })
            .catch(function (thrown) {
                console.log("coming in response on Failed", thrown);
                dispatch(bannerFailed(thrown));
            });
        }
}