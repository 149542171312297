import { BANNER_LIST, BANNER_LIST_SUCCESS, BANNER_LIST_FAILED } from "./bannerActionTypes";

const initialState = {
    loading: 0,
    
    items: [],
};

export default function bannerReducer(state = initialState, action) {

    console.log("action data ",action);
    switch (action.type) {
        case BANNER_LIST:
            return { ...state, action };
        case BANNER_LIST_SUCCESS:
            return { ...state, ...{ items: action.items } };
        case BANNER_LIST_FAILED:
            return { ...state, ...{ items: action.items } };

        default:
            return state;
    }
}
