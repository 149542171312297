import {
  PAGE_LIST,
  PAGE_LIST_SUCCESS,
  PAGE_LIST_FAILED,
  CONTACT_REQUEST,
  CONTACT_REQUEST_SUCCESS,
  CONTACT_REQUEST_FAILED,
  CONTACT_REQUEST_SUBSCRIBE,
  CONTACT_REQUEST_SUBSCRIBE_SUCCESS,
  CONTACT_REQUEST_SUBSCRIBE_FAILED,
} from "./pageActionTypes";

const initialState = {
    loading: 0,
    
    items: [],
};

export default function pageReducer(state = initialState, action) {

    console.log("action data ",action);
    switch (action.type) {
        case PAGE_LIST:
            return { ...state, ...{ items: { html_content:'',page_title:''}, loading: true } };
        case PAGE_LIST_SUCCESS:
            return {
              ...state,
              ...{ items: action.items, loading: false },
            };
        case PAGE_LIST_FAILED:
            return { ...state, ...{ items: action.items, loading: false } };

        default:
            return state;
    }
}


const initialcontactState = {
  loading: 0,

    items: [],
  subscribe:{loading:false,items:[]}
};

export    function contactReducer(state = initialcontactState, action) {
  console.log("action data ", action);
    switch (action.type) {
      case CONTACT_REQUEST:
        return {
          ...state,
          ...{ items: action.items, loading: true },
        };
      case CONTACT_REQUEST_SUCCESS:
        return {
          ...state,
          ...{ items: action.items, loading: false },
        };
      case CONTACT_REQUEST_FAILED:
        return { ...state, ...{ items: action.items, loading: false } };

      case CONTACT_REQUEST_SUBSCRIBE:
        return {
          ...state,
          ...{ subscribe: { items:[], loading: true } },
        };
      case CONTACT_REQUEST_SUBSCRIBE_SUCCESS:
        return {
          ...state,
          ...{ subscribe: { items: action.items, loading: false } },
        };
      case CONTACT_REQUEST_SUBSCRIBE_FAILED:
        return {
          ...state,
          ...{ subscribe: { items: action.items, loading: false } },
        };

      default:
        return state;
    }
}
