import { toast } from "react-toastify";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_UPDATE_QUANTITIES,
  CART_LIST_REQUEST,
  CART_LIST_SUCCESS,
  CART_LIST_FAILED,
  COUPON_ADDED,
  COUPON_ADDED_SUCCESS,
  COUPON_ADDED_FAILED,
} from "./cartActionTypes";
import { cartPoint } from "./endpoint";
import { Logintoken } from "../../common";
import { authCheckNull } from "../../common/AuthCheck";
export function cartAddItemSuccess(product, options = [], quantity = 1) {
  toast.success(`Product "${product.name}" added to cart!`);

  return {
    type: CART_ADD_ITEM,
    product,
    options,
    quantity,
  };
}

export function cartRemoveItemSuccess(itemId) {
  return {
    type: CART_REMOVE_ITEM,
    itemId,
  };
}

export function cartUpdateQuantitiesSuccess(quantities) {
  return {
    type: CART_UPDATE_QUANTITIES,
    quantities,
  };
}

export function cartAddItem(product, options = [], quantity = 1) {
  // sending request to server, timeout is used as a stub
  var logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
  return (dispatch) =>
    new Promise((resolve) => {
      var configurable = product.variants.length > 0 ? true : false;

      var split_options = product.sku.split("-variant-");
      var configuration = {};

      console.log("Product inserted data coming here ", product);
      if (product.variants.length > 0) {
        configuration = {
          quantity,
          is_configurable: true,
          selected_configurable_option: product.selectedvariations.id,
          super_attribute: { ...product.variations },
        };
      } else {
        console.log("check quantity", quantity);
        configuration = { quantity, is_configurable: false };
      }

      setTimeout(() => {
        console.log("Product inserted", product);
        fetch(cartPoint.add + product.id + logintoken, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(configuration),
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            dispatch(cartItemListGet());
            dispatch(cartAddItemSuccess(product, options, quantity));

            resolve();
            //return response;
          })
          .catch(function (thrown) {
            //  addToast("some error occured", { appearance: "error", autoDismiss: true });
          });
      }, 500);
    });
}

export function cartRemoveItem(itemId, productid = "") {
  // sending request to server, timeout is used as a stub
  var logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        fetch(cartPoint.delete + productid + logintoken)
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log("response coming here", response);
            dispatch(cartRemoveItemSuccess(itemId));
            resolve();
            //return response;
          })
          .catch(function (thrown) {
            toast.error(thrown.message);
          });
      }, 500);
    });
}
export function cartUpdateQuantities(data, addToast) {
  console.log("cart update data coming here", data);

  let cartupdate = { qty: { [data.id]: data.quantity + 1 } };

  // sending request to server, timeout is used as a stub
  const logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({ type: CART_LIST_REQUEST, items: [] });
      setTimeout(() => {
        fetch(cartPoint.update + logintoken, {
          method: "PUT", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(cartupdate),
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log("response coming here", response);
            dispatch(cartItemListGet());
            addToast(response.message, {
              appearance: "success",
              autoDismiss: true,
            });

            resolve();

            //return response;
          })
          .catch(function (thrown) {
              addToast('Quantity exceeds to available stock', {
                appearance: "error",
                autoDismiss: true,
              });
             dispatch(cartItemListGet());
          });  
      }, 500);
    });
}

export function cartUpdateRemoveQuantities(data, addToast) {
  console.log("cart update data coming here", data);

  let cartupdate = { qty: { [data.id]: data.quantity - 1 } };

  // sending request to server, timeout is used as a stub
  const logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
          dispatch({ type: CART_LIST_REQUEST, items: [] });
        fetch(cartPoint.update + logintoken, {
          method: "PUT", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(cartupdate),
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log("response coming here", response);
            dispatch(cartItemListGet());
            addToast(response.message, {
              appearance: "success",
              autoDismiss: true,
            });

            resolve();

            //return response;
          })
          .catch(function (thrown) {
            toast.error(thrown.message);
          });
      }, 500);
    });
}

export function cartListItemSuccess(items) {
  return {
    type: CART_LIST_SUCCESS,
    items: items.data.items != undefined ? items.data.items : [],
    subtotal: items.data.formated_sub_total,
    grandtotal: items.data.formated_grand_total,
    coupon_code: items.data.coupon_code,
    discount: items.data.formated_discount,
  };
}

export function cartListItemFailed(error) {
  return {
    type: CART_LIST_FAILED,
    error,
  };
}

export function cartItemListGet() {
  console.log("cart Item clicking here ", cartPoint.list);
  var logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        fetch(cartPoint.list + logintoken)
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log("response coming here", response);
            dispatch(cartListItemSuccess(response));
            resolve();
            //return response;
          })
          .catch(function (thrown) {
            ///toast.error(thrown.message);
            dispatch(cartListItemFailed(thrown));
          });
      }, 500);
    });
}

/****** Cart Coupon Check  ******/

export function couponAddedSuccess(items) {
  return {
    type: COUPON_ADDED_SUCCESS,
    items,
  };
}

export function couponAddedFailed(error) {
  return {
    type: COUPON_ADDED_FAILED,
    error,
  };
}

export function addCoupon(data, addToast) {
  var logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({ type: COUPON_ADDED, items: [], loading: true });
      setTimeout(() => {
        fetch(cartPoint.couponAdd + logintoken, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data),
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log("response coming here", response);

            if (!response.success) {
              toast.error(response.message);
              dispatch(couponAddedFailed(response));
              if (addToast) {
                addToast(response.message, {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            } else {
              toast.success("Coupon is added successfully!");
              if (addToast) {
                addToast(response.message, {
                  appearance: "success",
                  autoDismiss: true,
                });
              }
              dispatch(couponAddedSuccess(response));
            }

            dispatch(cartItemListGet());
            resolve();
            //return response;
          })
          .catch(function (thrown) {
            ///toast.error(thrown.message);
          });
      }, 500);
    });
}

/****** remove coupon detail Coming here ****/
export function RemoveCoupon(coponcode, addToast) {
  var logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({ type: COUPON_ADDED, items: [], loading: true });
      setTimeout(() => {
        fetch(cartPoint.couponAdd + logintoken, {
          method: "DELETE", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log("response coming here", response);

            if (!response.status) {
              toast.error(response.message);
              dispatch(couponAddedFailed(response));
              if (addToast) {
                addToast(response.message, {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            } else {
              toast.success("Coupon is Deleted successfully!");
              if (addToast) {
                addToast(response.message, {
                  appearance: "success",
                  autoDismiss: true,
                });
              }
              dispatch(couponAddedSuccess(response));
            }
            dispatch(cartItemListGet());

            resolve();
            //return response;
          })
          .catch(function (thrown) {
            ///toast.error(thrown.message);
            if (addToast) {
              addToast("Coupon failed to  add", {
                appearance: "error",
                autoDismiss: true,
              });
            }
            dispatch({
              type: COUPON_ADDED_FAILED,
              error: "coupon failed to add",
              loading: false,
            });
          });
      }, 500);
    });
}
