import PropTypes from "prop-types";
import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import ProductGrid from "./ProductGrid";
import SectionTitleWithText from "../../components/section-title/SectionTitleWithTextForTabProducts";
import SectionTitle from "../../components/section-title/SectionTitle";
import { connect } from "react-redux";
const TabProductEight = ({
  spaceTopClass,
  spaceBottomClass,
  bgColorClass,
  category,
  sectionTitle,
  productTabClass,
  product
}) => {
  return (
    <div
      className={`product-area hm9-section-padding  ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      } ${bgColorClass ? bgColorClass : ""}`}
    >
      <div className="container-fluid">
        {sectionTitle && (
          <SectionTitleWithText
            titleText="Featured Products"
            positionClass="text-center"
            // titleColor="text-white"
          />
        )}

        <Tab.Container defaultActiveKey="bestSeller">
          <Nav
            variant="pills"
            // className={`product-tab-list pb-55 text-center ${
            className={`product-tab-list d-none pb-90 pt-30 ${
              productTabClass ? productTabClass : ""
            }`}
          >
            <Nav.Item>
              <Nav.Link eventKey="newArrival">
                <h4>New Arrivals</h4>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="bestSeller">
                <h4>Best Sellers</h4>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="saleItems">
                <h4>Sale Items</h4>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="newArrival">
              <div className="row mt-50">
                <ProductGrid
                  category="newarrival"
                  type="new"
                  limit={8}
                  productlist={product.items}
                  spaceBottomClass="mb-25"
                  titlePriceClass="title-price-wrap-2-white"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="bestSeller">
              <div className="row mt-50">
                <ProductGrid
                  category="bestseller"
                  type="bestSeller"
                  limit={8}
                  productlist={product.bestseller.items}
                  spaceBottomClass="mb-25"
                  titlePriceClass="title-price-wrap-2-white"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="saleItems">
              <div className="row mt-50">
                <ProductGrid
                  category="salesitems"
                  type="salesitems"
                  limit={8}
                  productlist={product.saleItem.items}
                  spaceBottomClass="mb-25"
                  titlePriceClass="title-price-wrap-2-white"
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

TabProductEight.propTypes = {
  bgColorClass: PropTypes.string,
  category: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  sectionTitle: PropTypes.bool,
  productTabClass: PropTypes.string
};
const mapStateToProps = (state, ownProps) => {
  return {
    product: state.product,
  };
};

export default connect(mapStateToProps)(TabProductEight);

