import React, { Component, useEffect } from "react";
import Swiper from "react-id-swiper";
import heroSliderData from "../../data/hero-sliders/hero-slider-fourteen.json";
import HeroSliderFourteenSingle from "../../components/hero-slider/HeroSliderFourteenSingle.js";

import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getbanner } from "../../redux/banners";
import { frontUrl, storageUrl } from "../../common";


const HeroSliderFourteen = ({ getbanner, banners, dispatch }) => {

  useEffect(() => {
    // getbanner();
    getbanner();
  }, []);

  const params = {
    effect: "fade",
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    watchSlidesVisibility: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev ht-swiper-button-nav">
        <i className="pe-7s-angle-left" />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next ht-swiper-button-nav">
        <i className="pe-7s-angle-right" />
      </button>
    )
  };

  return (
    <div className="slider-area">
      <div className="slider-active-2 nav-style-3">
        <Swiper {...params}>
          {banners &&
              banners.items.map((single, key) => {
              return (
                <HeroSliderFourteenSingle
                  sliderClassName="swiper-slide"
                  data={single}
                  key={key}
                />
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    banners: state.banners,
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     getbanner: () => {
//       dispatch(getbanner());
//     },
//   }
// }

const mapDispatchToProps = {
  getbanner,
};

// export default HeroSliderFourteen;

export default connect(mapStateToProps, mapDispatchToProps)(HeroSliderFourteen);
