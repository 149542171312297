import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
// import { getBrands } from "../../redux/product"
// import { cartItemListGet, addCoupon, RemoveCoupon } from '../../redux/cart';

const NavMenu = ({ strings, cartItemListGet, menuWhiteClass, sidebarMenu, brands, category, spares, accessories, getBrands }) => {

  console.log("Brands List ", brands)

  const [categoryImage, setCategoryImage] = useState('');

  useEffect(() => {

    // cartItemListGet()
    // getBrands();
  }, []);
  console.log("category List  coming here ", category)
  return (
    <div
      className={` ${sidebarMenu
        ? "sidebar-menu"
        : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
        } `}
    >
       <nav>
        <ul>
          <li>
            <Link
              to={process.env.PUBLIC_URL}
              refresh="true"
              onClick={() =>
                (window.location.href = process.env.PUBLIC_URL + "/")
              }
            >
              Home
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/page/about-us"}>About Us</Link>
            {/* <Link to={process.env.PUBLIC_URL + "/"}>About Us</Link> */}
          </li>
          <li>
            <Link
              to={"/#"}
              refresh="true"
              onClick={() =>
                (window.location.href = process.env.PUBLIC_URL + "/")
              }
            >
              {" "}
              Bicycles Types
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-caret-down" />
              )}
            </Link>
            <ul className="submenu">
              {category.items.map((values, index) => {
                // return (
                //   <li onMouseOver={() => setCategoryImage(values.image_url)}>
                //     <Link
                //       to={process.env.PUBLIC_URL + "/catalog/" + values.slug}
                //     >
                //       <img src={values.image_url} />
                //     </Link>
                //   </li>
                // );
                return (
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/catalog/" + values.slug}
                    >
                      {values.name}
                    </Link>
                  </li>
                  //   <li><Link to={process.env.PUBLIC_URL + "/"} >{values.name}
                  //   </Link>
                  // </li>
                );
              })}
            </ul>
          </li>

          <li>
            <a href={process.env.PUBLIC_URL + "/contact "}>Contact Us</a>
            {/* <Link to={process.env.PUBLIC_URL + "/"}>Contact Us</Link> */}
          </li>

          {/* <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu mega-menu-padding">
            </ul>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

const changeImage = (image) => {

};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object,
};
const mapStateToProps = state => {
  return {
    category: state.category,
    // brands: state.brands,
    // spares: state.childcat.spares,
    // accessories: state.childcat.accessories,

  }
}

const mapDispatchToProps = {
  // getBrands: getBrands,
  // cartItemListGet: cartItemListGet

}

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(NavMenu));
