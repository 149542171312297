import { toast } from "react-toastify";
import {
  ORDERS_LIST_SUCCESS,
  ORDERS_LIST_FAILED,
  ORDERS_LIST,
  ORDERS_DETAIL,
  ORDERS_DETAIL_SUCCESS,
  ORDERS_DETAIL_FAILED,
} from "./orderActionTypes";
import { ordersPoint } from "./endpoint";
import { axios } from "axios";
import { loginSuccess } from "../auth";
import { Logintoken } from "../../common";

export function ordersSuccess(products) {
    console.log("coming here to check the order list")
    return {
        type: ORDERS_LIST_SUCCESS,
        items: products,
    };
}

export function ordersFailed(error) {
    return {
        type: ORDERS_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getorders(page=1) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => {
   
    fetch(ordersPoint.list+'?token='+Logintoken()+'&limit=10&page='+page
    )
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                dispatch(ordersSuccess(response));
            })
            .catch(function (thrown) {
                dispatch(ordersFailed(thrown));
            });
        }       
}

/***** Order Detail Page  ********/

export function orderdetailSuccess(orderdata) {
  console.log("coming here to check the order list");
  return {
    type:ORDERS_DETAIL_SUCCESS,
    items: orderdata.data,
  };
}

export function orderdetailFailed(error) {
  return {
    type:ORDERS_DETAIL_FAILED,
    items: [],
    error: error,
  };
}

export function getorderdetail(orderid) {
  // sending request to server, timeout is used as a stub
    return (dispatch) => {
        dispatch({ type: ORDERS_DETAIL, items: {},loading:true})
    fetch(
      ordersPoint.list + "/" + orderid + "?token=" + Logintoken() 
    )
      .then(function (response) {
        return response.json();
      })
      .then((response) => {
        dispatch(orderdetailSuccess(response));
      })
      .catch(function (thrown) {
        dispatch(orderdetailFailed(thrown));
      });
  };
}
