export default function()
{
   let auth  =  localStorage.getItem("auth")
   
   if(authCheckNull())
   {
       window.location.href = '/account/login'
   }






}
/**********Check if Auth Null  */
export function authCheckNull()
{
   return localStorage.getItem("auth")==undefined

}

/***Local Storage  ****/
export function AuthData()
{
   return JSON.parse(localStorage.getItem("auth"));
}