import {
    PRODUCT_LIST_SUCCESS,
    BRANDS_LIST_SUCCESS,
    PRODUCT_LIST_FAILED,
    NEW_PRODUCT_LIST_SUCCESS,
    NEW_PRODUCT_LIST_FAILED,
    PRODUCT_DETAIL_FAILED,
    PRODUCT_DETAIL_SUCCESS,
    PRODUCT_DETAIL,
    PRODUCT_ADDITIONALS,
    PRODUCT_ADDITIONALS_SUCCESS,
    PRODUCT_ADDITIONALS_FAILED,
    PRODUCT_LIST,
    PRODUCT_VARIATION_DETAIL_FAILED,
    PRODUCT_VARIATION_DETAIL_SUCCESS,
    PRODUCT_VARIATION_DETAIL,
    PRODUCT_FILTERS_SUCCESS,
    PRODUCT_FILTERS,
    PRODUCT_RECENT_VIEWED_ADD,
    PRODUCT_RECENT_VIEWED_LIST,
    BEST_SELLER_LIST_SUCCESS,
    BEST_SELLER_LIST_FAILED,
    SALE_ITEM_LIST_SUCCESS,
    SALE_ITEM_LIST_FAILED,
    PRODUCT_WITH_ADDITIONAL_DETAILS_LIST, 
    PRODUCT_WITH_ADDITIONAL_DETAILS_LIST_FAILED, 
    PRODUCT_WITH_ADDITIONAL_DETAILS_LIST_SUCCESS

} from "./productActionTypes";
import { brandPoint, productPoint} from "./endpoint";
import { axios } from "axios";

export function productSuccess(products) {

    return {
        type: PRODUCT_LIST_SUCCESS,
        loading: false,
        items: products.data,
        itemmeta: products.meta,
    };
}

export function productFailed(error) {
    return {
        type: PRODUCT_LIST_FAILED,
       
        loading: false,
        error: error,
    };
}

/*** Get Parameter ****/
function getSearchParameters() {
    var prmstr = window.location.search.substr(1);
    return prmstr != null && prmstr != "" ? '&'+prmstr : '';
}
export function getProduct(cat, slug = "") {
    // sending request to server, timeout is used as a stub
    var urlparams = window.location.href.split('?');
    return (dispatch) => {
        dispatch({ type: PRODUCT_LIST, loading: true, items: [] });
        // dispatch({ type: PRODUCT_LIST, loading: true });
         var url = encodeURI(productPoint.list + (slug != "" ? "?cat_slug=" + slug : "?category_id=" + cat)+(urlparams.length>0?"&"+urlparams[1]:''));
         fetch(url)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(productSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
               // dispatch(productFailed(thrown));
            });
    };
}

/*********
 *
 * Product Detail Actions
 *
 */
export function productDetailSuccess(productDetail) {
    return {
        type: PRODUCT_DETAIL_SUCCESS,
        items: productDetail,
    };
}

export function productDetailFailed(error) {
    return {
        type: PRODUCT_DETAIL_FAILED,
        items: [],
        error: error,
    };
}

export function getProductDetail(sku) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => {
        dispatch({ type: PRODUCT_DETAIL, loading: true });
        fetch(productPoint.list + "?category_id=1&url_key=" + sku)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {

                dispatch(productDetailSuccess(response));
                dispatch(getProductDetailAdditionals(response.data[0].id))
                dispatch(getproductDetailVariation(response.data[0].id));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(productDetailFailed(thrown));
            });
    };
}

/*********
 *
 * Product Variatons Actions
 *
 */
export function productDetailVariationSuccess(productDetail) {
    return {
        type: PRODUCT_VARIATION_DETAIL_SUCCESS,
        items: productDetail.data,
    };
}

export function productDetailVariationFailed(error) {
    return {
        type: PRODUCT_VARIATION_DETAIL_FAILED,
        items: [],
    };
}

export function getproductDetailVariation(id) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => {
        dispatch({ type: PRODUCT_VARIATION_DETAIL, items: [], loading: true });
        fetch(productPoint.variations + "/" + id)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(productDetailVariationSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(productDetailVariationFailed(thrown));
            });
    };
}

/******
 * Get Product Details  Addtionals Actions
 */

export function productDetailAdditionalsSuccess(productDetail) {
    return {
        type: PRODUCT_ADDITIONALS_SUCCESS,
        items: productDetail.data,
    };
}

export function productDetailAdditionalsFailed(error) {
    return {
        type: PRODUCT_ADDITIONALS_FAILED,
        items: [],
        error: error,
    };
}

export function getProductDetailAdditionals(id) {
    return (dispatch) => {
        dispatch({ type: PRODUCT_ADDITIONALS, loading: true });
        fetch(productPoint.additions + "/" + id)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(productDetailAdditionalsSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(productDetailAdditionalsFailed(thrown));
            });
    };
}

/*****
 *
 * new Products List Actions
 *
 */
export function newProductSuccess(products) {
    return {
        type: NEW_PRODUCT_LIST_SUCCESS,
        items: products.data,
    };
}

export function newProductFailed(error) {
    return {
        type: NEW_PRODUCT_LIST_FAILED,
        items: [],
        error: error,
    };
}

/*****
 *
 * Products with additional details List Actions
 *
 */
export function productWithAdditionalDetailsSuccess(productsWithAllDetails) {
    return {
        type: PRODUCT_WITH_ADDITIONAL_DETAILS_LIST_SUCCESS,
        items: productsWithAllDetails,
    };
}

export function productWithAdditionalDetailsFailed(error) {
    return {
        type: PRODUCT_WITH_ADDITIONAL_DETAILS_LIST_FAILED,
        items: [],
        error: error,
    };
}


export function getproductWithAdditionalDetails() {
    // sending request to server, timeout is used as a stub

    return (dispatch) =>
        fetch(productPoint.productWithAdditionalDetails)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(productWithAdditionalDetailsSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(productWithAdditionalDetailsFailed(thrown));
            });
}




export function getBrandsSuccess(brands) {
    return {
        type: BRANDS_LIST_SUCCESS,
        items: brands.data,
    };
}

export function getnewproduct({ limit, category,attribute }) {
    // sending request to server, timeout is used as a stub

    return (dispatch) =>
        fetch(productPoint.list + "?category_id=" + category + "&limit=" + limit+attribute)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(newProductSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(newProductFailed(thrown));
            });
}



/***** new Best Seller Success  ****/
export function bestSellerProductSuccess(products) {
    return {
        type: BEST_SELLER_LIST_SUCCESS,
        items: products.data,
    };
}

export function bestSellerProductFailed(error) {
    return {
        type: BEST_SELLER_LIST_FAILED,
        items: [],
        error: error,
    };
}

/********* Product Best Seller Product  *******/
export function getBestSellerProduct({ limit, category,attribute }) {
    // sending request to server, timeout is used as a stub

    return (dispatch) =>
        
        fetch(productPoint.list + "?category_id=" + category+attribute+ "&limit=" + limit)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(bestSellerProductSuccess(response)); 
            })
            .catch(function (thrown) {
                dispatch(bestSellerProductFailed(thrown));
            });
}


/***** Sale Item  Success  ****/
export function saleItemProductSuccess(products) {
    return {
        type: SALE_ITEM_LIST_SUCCESS,
        items: products.data,
    };
}

export function saleItemProductFailed(error) {
    return {
        type: SALE_ITEM_LIST_FAILED,
        items: [],
        error: error,
    };
}

/********* Product Best Seller Product  *******/
export function getSaleItemProduct({ limit, category,attribute }) {
    // sending request to server, timeout is used as a stub

    return (dispatch) =>
        fetch(productPoint.list + "?category_id=" + category+attribute+ "&limit=" + limit)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(saleItemProductSuccess(response)); 
            })
            .catch(function (thrown) {
                dispatch(saleItemProductFailed(thrown));
            });
}

/****** Get Brands  ********/

export function getBrands() {
    return (dispatch) =>
        fetch(brandPoint)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(getBrandsSuccess(response));
                //return response;
            });
}

export function getProductFiltersSuccess(brands) {
    return {
        type: PRODUCT_FILTERS_SUCCESS,
        items: brands.data,
    };
}

/****** Get Brands  ********/

export function getProductFilters(cat_slug) {
    return (dispatch) => {
        dispatch({ type: PRODUCT_FILTERS, loading: true, items: [] });
        fetch(productPoint.filters + "?cat_slug=" + cat_slug)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(getProductFiltersSuccess(response));
                //return response;
            });
    };
}

export function pageChangeAction(event) {
    return (dispatch) =>
        dispatch({
            type: "SET_OPTION_VALUE",
            options: "page",
            value: event,
        });
}

export function addRecentViewedSuccess(data)
{
    var recent_viewed  = localStorage.getItem('recent_viewed');
    var recentviewed =[];
    if(data !=null)
    {
         var recentviewed = JSON.parse(recent_viewed);
         const found = recentviewed.some(el => el.id === data.id);
         if (!found) recentviewed.push(data);
         
    }
    else{
        recentviewed.push(data);
    }
   localStorage.setItem('recent_viewed',JSON.stringify(recentviewed))
    return {
        
            type: PRODUCT_RECENT_VIEWED_LIST,
            items: recentviewed
        
    };
}

/******** Add Recent Viewed ******/
export function addRecentViewed(data)
{
    return (dispatch)=>dispatch(addRecentViewedSuccess(data));
}



export function listRecentViewedSuccess(data)
    {
        
        return {
        type: PRODUCT_RECENT_VIEWED_LIST,
        items: data
    };
  
}

/******** Add Recent Viewed ******/
export function listRecentViewed()
{
    return (dispatch)=>dispatch(addRecentViewedSuccess(localStorage.getItem('recent_viewed')));
}