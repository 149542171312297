import { ADDRESS_LIST, ADDRESS_LIST_FAILED, ADDRESS_LIST_SUCCESS,CREATE_ADDRESS,
    CREATE_ADDRESS_FAILED,CREATE_ADDRESS_SUCCESS,
    UPDATE_ADDRESS,UPDATE_ADDRESS_FAILED,UPDATE_ADDRESS_SUCCESS,
    ADDRESS_EDIT,ADDRESS_EDIT_SUCCESS,ADDRESS_EDIT_FAILED } from "./addressActionTypes";

/*
 * item example:
 * {
 *   id: 1,
 *   product: {...}
 *   options: [
 *     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
 *   ],
 *   price: 250,
 *   quantity: 2,
 *   total: 500
 * }
 * extraLine example:
 * {
 *   type: 'shipping',
 *   title: 'Shipping',
 *   price: 25
 * }
 */
const initialState = {
    loading: 0,
    list: {loading:false,items:[]},
    create: {loading:false,items:[]},
    update: {loading:false,items:[]},
    edit: {loading:false,items:[]},
};  

export default function addressReducer(state = initialState, action) {
    switch (action.type) {
        case ADDRESS_LIST:
            return { ...state,  ...{list: {loading:true,items:[]}} };
        case ADDRESS_LIST_SUCCESS:
            return { ...state,  ...{list: {loading:false,items:action.items}} };
        case ADDRESS_LIST_FAILED:
            return { ...state,  ...{list: {loading:false,items:[]}} }; 
        case CREATE_ADDRESS:
            return { ...state,  ...{create: {loading:true,items:[]}} };
        case CREATE_ADDRESS_SUCCESS:
            return { ...state, ...{create: {loading:false,items:action.items}} };
        case CREATE_ADDRESS_FAILED:
            return { ...state,  ...{create: {loading:false,items:[]}} };     
        case ADDRESS_EDIT:
            return { ...state,  ...{edit: {loading:true,items:[]}} };
        case ADDRESS_EDIT_SUCCESS:
            return { ...state,  ...{edit: {loading:false,items:action.items}} };
        case ADDRESS_EDIT_FAILED:
            return { ...state,  ...{edit: {loading:false,items:[]}} }; 
        case UPDATE_ADDRESS:
            return { ...state,  ...{update: {loading:true,items:[]}} };
        case UPDATE_ADDRESS_SUCCESS:
            return { ...state,  ...{update: {loading:false,items:action.items}} };
        case UPDATE_ADDRESS_FAILED:
            return { ...state,  ...{update: {loading:false,items:[]}} };       
        default:
            return state;
    }
}
