import currencyReducer from "./currencyReducer";

import cartReducer from "./cartReducer";
import wishlistReducer, {wishlistDataReducer} from "../wishlist/wishlistReducer";
import compareReducer from "./compareReducer";
import bannersReducer from "../banners";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import {
  newProductReducer,
  brandReducer,
  detailProductReducer,
  detailProductAdditionalsReducer,
  detailProductVariationsReducer,
  productFiltersReducer,
  productWithAdditionalDetailsReducer
  
} from "../product/productReducer";
import productlistReducer from "../product/productReducer";
import categoryReducer,{categorychildReducer} from "../category/categoryReducer";
import {ordersReducer} from "../order/orderReducer";
import {cartListReducer} from "../reducers/cartReducer";
import authReducer, { stateReducer } from "../auth/authReducer";
import {
  SaveAddressReducer,
  SaveOrderMethodReducer,
  PaymentMethodListReducer,
} from "../checkout/checkoutReducer";
import addressReducer from "../address";
import reviewReducer from "../review/reviewReducer"
import cartnewReducer from "../../redux/cart/cartReducer"
import pageReducer from "../../redux/page/";
import { contactReducer } from "../../redux/page/pageReducer";


const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productlistReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  banners: bannersReducer,
  newarrivals: newProductReducer,
  newArrivalsWithAllData: productWithAdditionalDetailsReducer,
  bestsellers: newProductReducer,
  saleitems: newProductReducer,
  brands: brandReducer,
  productdetail: detailProductReducer,
  productadditions: detailProductAdditionalsReducer,
  productvariations: detailProductVariationsReducer,
  productfilter: productFiltersReducer,
  category: categoryReducer,
  orderdata: ordersReducer,
  cartlist: cartListReducer,
  wishlist: wishlistDataReducer,
  childcat: categorychildReducer,
  states: stateReducer,
  saveaddress: SaveAddressReducer,
  saveorder: SaveOrderMethodReducer,
  auth: authReducer,
  address: addressReducer,
  review: reviewReducer,
  cart: cartnewReducer,
  product: newProductReducer,
  page: pageReducer,
  paymentmethods: PaymentMethodListReducer,
  contact: contactReducer,
});

export default rootReducer;
