import { toast } from "react-toastify";
import { CATEGORY_LIST_SUCCESS, CATEGORY_LIST_FAILED, CHILD_CATEGORY_LIST, CHILD_CATEGORY_LIST_SUCCESS, CHILD_CATEGORY_LIST_FAILED } from "./categoryActionTypes";
import { categoryPoint } from "./endpoint";

export function categorySuccess(categories) {
    console.log("categories ", categories);
    return {
        type: CATEGORY_LIST_SUCCESS,
        items: categories.data,
    };
}

export function categoryFailed(error) {
    return {
        type: CATEGORY_LIST_FAILED,
        items: [],

        error: error,
    };
}

export function getCategories() {
    console.log("action get category coming here");
    return (dispatch) =>
        fetch(categoryPoint.list)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                dispatch(categorySuccess(data));
            })
            .catch(function (thrown) {
                dispatch(categoryFailed(thrown));
            });
}

export function categorychildSuccess(categories,cat) {
    console.log("categories ", cat);
    return {
        type: CHILD_CATEGORY_LIST_SUCCESS,
        items: categories.data,
        cattype:cat
    };
}

export function categorychildFailed(error) {
    return {
        type: CHILD_CATEGORY_LIST_FAILED,
        items: [],
        
        error: error,
    };
}

export function getChildCategories(id,cat) {
    console.log("action get category coming here");
    return (dispatch) =>
        fetch(categoryPoint.childList+id)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                dispatch(categorychildSuccess(data,cat));
            })
            .catch(function (thrown) {
                dispatch(categorychildFailed(thrown));
            });
}
