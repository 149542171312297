
import { toast } from 'react-toastify';
import { Logintoken ,frontUrl} from "../../common";

import {
    LOGIN,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_FAILED,
    PROFILE,
    PROFILE_FAILED,
    PROFILE_SUCCESS,
    RESET,
    RESET_SUCCESS,
    RESET_FAILED,
    STATELIST,
    STATELIST_SUCCESS,
    STATELIST_FAILED,
    OTP_LOGIN,
    OTP_LOGIN_SUCCESS,
    OTP_LOGIN_FAILED
} from "./authActionTypes";
import { authPoint } from "./endpoint";

/****** Login customer Success Action   *****/
export function loginSuccess(login) {

    toast.success('You are Logged in successfully!');
   // BrowserRouter.push(frontUrl+`/account/dashboard`)
   console.log("login data coming here",login)  
   localStorage.setItem("auth",JSON.stringify(login))
  var url = new URL(window.location.href);
    var c = url.searchParams.get("redirect");
    var redirecturl = frontUrl + '/my-account';
    if (c != undefined && c != '') {
        redirecturl = frontUrl +'/'+ c;

    }  
    
    console.log("redirect url ", redirecturl);

   setTimeout(function(){ window.location.href = redirecturl; }, 1000)
   // history.push(frontUrl+`/account/dashboard`)
 
    return {
        type: LOGIN_SUCCESS,
        items: login.list,
    };
}

/****** Login customer Failed Action  *********/
export function loginFailed(error) {

    toast.error(error);
    return {
        type: LOGIN_FAILED,
        items: [],
        error: error,
    };
}


/********** Login customer Action ************/
export function login(data,addtoast) {
    // sending request to server, timeout is used as a stub
    //toast.error("Password and email is Wrong");
    return (dispatch) => {
        dispatch({ type: LOGIN, items: [], loading: true });
        fetch(authPoint.login, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },  
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {

                
                if(data.error !=undefined)
                {
                    
                    console.log("Coming here bro ",data)
                  
                    addtoast("Password and email is Wrong", { appearance: "error", autoDismiss: true });
                    //alert(data.error);
                    dispatch(loginFailed(data.error ));
                   
                }
                else
                {
                    dispatch({ type: LOGIN_SUCCESS, items: data, loading: false })
                    addtoast("Logged Successfully!", { appearance: "success", autoDismiss: true });
                    dispatch(loginSuccess(data));
                }
                
            })
            .catch(function (thrown) {
                console.log("thrown error",thrown)
                addtoast("Password and email is Wrong", { appearance: "error", autoDismiss: true });
            });
    };
}


/****** register customer Success Action   *****/
export function registerSuccess(register) {
   // alert("You are Registered successfully! ")
    toast.success('You are Registered successfully! ,');
    return {
        type: REGISTER_SUCCESS,
        items: register.list,
    };
}

/****** register customer Failed Action  *********/
export function registerFailed(error) {
    return {
        type: REGISTER_FAILED,
        items: [],
        error: error,
    };
}


/********** register customer Action ************/
export function register(data,addToast) {
    // sending request to server, timeout is used as a stub

    return (dispatch) => {  
        dispatch({ type: REGISTER, items: [], loading: true });
        fetch(authPoint.register, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },  
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          })
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                addToast("You are Signed up Successfully!", { appearance: "success", autoDismiss: true });
                dispatch(registerSuccess(response));
                dispatch(login(data,addToast));
            })
            .catch(function (thrown) {
                dispatch(registerFailed(thrown));
                addToast("Some Error Occured", { appearance: "error", autoDismiss: true });
            });
    };
}

/****** register customer Success Action   *****/
export function profileEditSuccess(register) {

    toast.success('Profile Updated Successfully! ,');
    return {
        type: PROFILE_SUCCESS,
        items: register.list,
    };
}

/****** register customer Failed Action  *********/
export function profileEditFailed(error) {
    return {
        type: PROFILE_FAILED,
        items: [],
        error: error,
    };
}

/********** Profile Edit customer Action ************/
export function profileEdit(data,addtoast) {
    // sending request to server, timeout is used as a stub
    
    return (dispatch) => {  
        dispatch({ type: PROFILE, items: [], loading: true });
        fetch(authPoint.updateprofile+'?token='+Logintoken(), {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },  
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          })
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                let authdata = {
                                token:Logintoken(),
                                data:response.data
                } 
                localStorage.setItem('auth',JSON.stringify(authdata));
              //  setTimeout(function(){ window.location.href = frontUrl+'/account/dashboard'; }, 1000) 
              addtoast("Profile Updated successfully", { appearance: "success", autoDismiss: true });
             
              dispatch(profileEditSuccess(response));
               
            })
            .catch(function (thrown) {
                //addtoast.error("Failed To update data");  
                addtoast("Failed To update data", { appearance: "error", autoDismiss: true });
                dispatch(profileEditFailed(thrown));
            });
    };
}



/********** reset customer Action ************/
export function accountResetPassAction(data) {
    // sending request to server, timeout is used as a stub

    return (dispatch) => {
        dispatch({ type: RESET, items: [], loading: true });
        fetch(authPoint.reset,  {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },  
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                dispatch(resetSuccess(data));
            })
            .catch(function (thrown) {
                dispatch(resetFailed(thrown));
            });
    };
}



/****** profile customer Success Action   *****/
export function profileSuccess(profile) {
    return {
        type: PROFILE_SUCCESS,
        items: profile.list,
    };
}

/****** profile customer Failed Action  *********/
export function profileFailed(error) {
    return {
        type: PROFILE_FAILED,
        items: [],
        error: error,
    };
}


/********** profile customer Action ************/
export function profile(data) {
    // sending request to server, timeout is used as a stub

    return (dispatch) => {
        dispatch({ type: PROFILE, items: [], loading: true });
        fetch(authPoint.profile, data)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                dispatch(profileSuccess(data));
            })
            .catch(function (thrown) {
                dispatch(profileFailed(thrown));
            });
    };
}



/****** reset customer Success Action   *****/
export function resetSuccess(reset) {
    return {
        type: RESET_SUCCESS,
        items: reset.list,
    };
}

/****** reset customer Failed Action  *********/
export function resetFailed(error) {
    return {
        type: RESET_FAILED,
        items: [],
        error: error,
    };
}


/********** reset customer Action ************/
export function resetAction(data) {
    // sending request to server, timeout is used as a stub

    return (dispatch) => {
        dispatch({ type: RESET, items: [], loading: true });
        fetch(authPoint.forgot_password,  {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },  
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                dispatch(resetSuccess(data));
            })
            .catch(function (thrown) {
                dispatch(resetFailed(thrown));
            });
    };
}



/****** reset states Success Action   *****/
export function statesSuccess(data) {
    return {
        type: STATELIST_SUCCESS,
        items: data.data,
    };
}

/****** reset states Failed Action  *********/
export function statesFailed(error) {
    return {
        type: STATELIST_FAILED,
        items: [],
        error: error,
    };
}


/********** reset customer Action ************/
export function stateslistAction() {
    // sending request to server, timeout is used as a stub

    return (dispatch) => {
        dispatch({ type: STATELIST, items: [], loading: true });
        fetch(authPoint.statelist )
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                dispatch(statesSuccess(response));
            })
            .catch(function (thrown) {
                dispatch(statesFailed(thrown));
            });
    };
}


/****** reset states Success Action   *****/
export function OtploginSuccess(data) {
    return {
        type: OTP_LOGIN_SUCCESS,
        items: data,
    };
}

/****** reset states Failed Action  *********/
export function OtploginFailed(error) {
    return {
        type: OTP_LOGIN_FAILED,
        items: [],
        error: error,
    };
}



/****** reset states Success Action   *****/
export function OtpVerifySuccess(data) {

    toast.success('You are Logged in successfully!');
    // BrowserRouter.push(frontUrl+`/account/dashboard`)
    console.log("login data coming here",data.token)  
    localStorage.setItem("auth",JSON.stringify(data.token))
   
   // setTimeout(function(){ window.location.href = frontUrl+'/account/dashboard'; }, 1000)
    return {
        type: OTP_LOGIN_SUCCESS,
        items: data.token,
    };
}

/****** reset states Failed Action  *********/
export function OtpVerifyFailed(error) {  
    return {
        type: OTP_LOGIN_FAILED,
        items: [],
        error: error,
    };
}
/******* Expport Verify Send  */
export function OtpVerifySend(data)
{

    return (dispatch) => {
        dispatch({ type: LOGIN, items: [], loading: true });
        fetch(authPoint.VerifyOtp, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },  
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                localStorage.removeItem('authmobile');
                if(!data.status)
                {
                    toast.error(data.message);
                    dispatch(OtpVerifyFailed(data.error ));
                    
                   // localStorage('authmobile',data.phone);
                }
                else
                {
                    dispatch(OtpVerifySuccess(data));
                }

              
                
            })
            .catch(function (thrown) {
                //console.log("thrown error",OtpVerifyFailed)
                toast.error('OTP is Expired or Invalid , please try again!');
                localStorage.removeItem('authmobile');
               
                OtpVerifyFailed(thrown)
                dispatch({ type: OTP_LOGIN, items: [], loading: true });
            });
    };

}   

/********** Login customer Action ************/
export function OtploginSend(data) {
    // sending request to server, timeout is used as a stub
    
    return (dispatch) => {
        dispatch({ type: OTP_LOGIN, items: [], loading: true });
        fetch(authPoint.OtpLogin, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
           
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },  
          
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                if(!data.status)
                {
                    dispatch(OtploginFailed(data.error));
                }
                else
                {
                    localStorage.setItem('authmobile',data.phone);
                    dispatch(OtploginSuccess(data));  
                }
                
            })
            .catch(function (thrown) {
                console.log("thrown error",thrown)
                
            });
    };
}